.header-menu {
    position: fixed;
    top: 0px;
    width: 90%;
    padding: 0px;
    margin: 0px;
    padding-top: 2rem;

    
}

.header-menu-item {
    font-family: "Jura", sans-serif;
    font-size: 3rem;
    float: right;
    margin: 0px;
    margin-left: 3rem;
    color: #ffffff;
    user-select: none;
    background-color: #0000;
    border: none;

}

@media(max-width: 400px) {
    .header-menu-item {
        font-size: 2rem;
        margin-left: 1rem;
    }
}